import { Route } from '@angular/router';

export const pagesRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'customers',
    pathMatch: 'full'
  },
  {
    path: 'customers',
    children: [
      {
        path: '',
        loadComponent: () => import('./customers/customers.component').then(c => c.CustomersComponent),
        pathMatch: 'full'
      },
      {
        path: 'new',
        loadComponent: () => import('./customers/customer-form/customer-form.component').then(c => c.CustomerFormComponent)
      },
      {
        path: ':id',
        loadComponent: () => import('./customers/customer-form/customer-form.component').then(c => c.CustomerFormComponent)
      },
    ]
  }
];

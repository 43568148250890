import { ApplicationConfig, DEFAULT_CURRENCY_CODE, importProvidersFrom, inject, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { APP_TYPE, AuthService, REFRESH_TOKEN_KEY, TOKEN_KEY } from '@af-web/auth';
import { provideToastr } from 'ngx-toastr';
import { JwtModule } from '@auth0/angular-jwt';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideEnvironmentNgxCurrency } from 'ngx-currency';
import { MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { provideIcons } from '@ng-icons/core';
import { heroBuildingOffice, heroDocument, heroMagnifyingGlass, heroTruck } from '@ng-icons/heroicons/outline';
import { saxSearchNormal1Outline, saxSearchNormalOutline } from '@ng-icons/iconsax/outline';
import {
  lucideBuilding,
  lucideBuilding2,
  lucideCalendar,
  lucideCalendarRange,
  lucideCheck,
  lucideChevronDown,
  lucideCoins,
  lucideCreditCard,
  lucideFileStack,
  lucideFileText,
  lucideFilter,
  lucideHandCoins,
  lucideHandshake,
  lucideLogOut,
  lucideMailCheck,
  lucideSearch,
  lucideSettings,
  lucideTruck
} from '@ng-icons/lucide';
import { AppsEnum, IconResgistry, MenuService } from '@af-web/shared';
import { VdAuthService } from './services/auth/vd-auth.service';
import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { vdAuthInterceptor } from './interceptors/auth/vd-auth.interceptor';
import { VdMenuService } from './services/menu/vd-menu.service';
import ptBr from '@angular/common/locales/pt';
registerLocaleData(ptBr);

export function tokenGetter() {
  const tokenKey = inject(TOKEN_KEY);
  return localStorage.getItem(tokenKey);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideToastr({
      toastClass: 'ngx-toastr afw-toastr'
    }),
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter
        }
      })
    ),
    provideHttpClient(
      withInterceptors([
        vdAuthInterceptor
      ])
    ),
    provideAnimationsAsync(),
    provideEnvironmentNgxMask(),
    provideEnvironmentNgxCurrency({}),
    provideNativeDateAdapter(),
    provideIcons({
      heroTruck,
      heroBuildingOffice,
      heroDocument,
      heroMagnifyingGlass,
      saxSearchNormalOutline,
      saxSearchNormal1Outline,
      lucideSearch,
      lucideCalendar,
      lucideCalendarRange,
      lucideCheck,
      lucideCoins,
      lucideCreditCard,
      lucideFilter,
      lucideHandCoins,
      lucideHandshake,
      lucideChevronDown,
      lucideTruck,
      lucideBuilding,
      lucideBuilding2,
      lucideFileText,
      lucideFileStack,
      lucideMailCheck,
      lucideSettings,
      lucideLogOut
    }),
    { provide: APP_TYPE, useValue: AppsEnum.VIRTUE_DYNAMICS },
    { provide: TOKEN_KEY, useValue: 'vdatkn' },
    { provide: REFRESH_TOKEN_KEY, useValue: 'vdrtkn' },
    { provide: AuthService, useClass: VdAuthService },
    { provide: MenuService, useClass: VdMenuService },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {
        dateFormat: 'shortDate'
      }
    },
    {
      provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL'
    },
    {
      provide: MatIconRegistry, useClass: IconResgistry
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline'
      }
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ]
};

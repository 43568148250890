import { HttpErrorResponse, HttpInterceptorFn, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { TokenService } from '@af-web/auth';
import { ToastrService } from 'ngx-toastr';
import { catchError, switchMap } from 'rxjs';
import { VdAuthService } from '../../services/auth/vd-auth.service';
import { VdConfigService } from '../../services/config/vd-config.service';

export const vdAuthInterceptor: HttpInterceptorFn = (req, next) => {
  const _configService: VdConfigService = inject(VdConfigService);
  const _tokenService: TokenService = inject(TokenService);
  const _authService: VdAuthService = inject(VdAuthService);
  const _toastrService: ToastrService = inject(ToastrService);
  const token = _tokenService.token;

  const WHITE_LIST = [
    `${_configService.baseUrl}/auth/login`
  ];

  const handleHttpError = (httpResponseError: HttpErrorResponse) => {
    switch (httpResponseError.status) {
      case HttpStatusCode.Forbidden:
        _authService.logout();
        break;
      case 0:
        _toastrService.error('Não foi possível se conectar ao servidor! Por favor contate o suporte!');
        break;
    }
  };

  if (!token && WHITE_LIST.includes(req.url))
    return next(req).pipe(
      catchError(
        (httpResponseError: HttpErrorResponse) => {
          handleHttpError(httpResponseError);
          return next(req);
        }
      )
    );

  const refreshToken = _tokenService.refreshToken;
  if (refreshToken && req.url.includes(`${_configService.baseUrl}/auth/refresh`)) {
    const newReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + refreshToken)
    });
    return next(newReq).pipe(
      catchError(
        (httpResponseError: HttpErrorResponse) => {
          handleHttpError(httpResponseError);
          return next(newReq);
        }
      )
    );
  }

  if (_tokenService.isTokenExpired()) {
    return _authService.refresh().pipe(
      switchMap(
        () => {
          return next(
            req.clone({
              headers: req.headers.set('Authorization', 'Bearer ' + _tokenService.refreshToken)
            })
          ).pipe(
            catchError(
              (httpResponseError: HttpErrorResponse) => {
                handleHttpError(httpResponseError);
                return next(req);
              }
            )
          );
        }
      )
    );
  }

  if (_tokenService.isTokenValid()) {
    const newReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + token)
    });
    return next(newReq).pipe(
      catchError(
        (httpResponseError: HttpErrorResponse) => {
          handleHttpError(httpResponseError);
          return next(newReq);
        }
      )
    );
  }

  return next(req).pipe(
    catchError(
      (httpResponseError: HttpErrorResponse) => {
        handleHttpError(httpResponseError);
        return next(req);
      }
    )
  );
};

import { Injectable } from '@angular/core';
import { Menu, MenuService } from '@af-web/shared';

@Injectable({
  providedIn: 'root'
})
export class VdMenuService  extends MenuService {

  override get menus(): Array<Menu> {
    return [
      {
        id: 'customers',
        routerLink: '/customers',
        label: 'Clientes',
        menuIcon: 'lucideBuilding2'
      },
    ];
  }
}